.progress-bar-container {
  position: relative;
  height: 20px; /* Hauteur de la barre de progression */
  width: 100%;
  padding-bottom: 5%;
}

.progress-bar {
  display: flex;

}


.magnifier {
  position: absolute;
  width: 17%;
  height: 40%;
  top: -25px; /* Ajustez cela en fonction de la taille de votre loupe */
  transform: translateX(-50%);
  font-size: 24px; /* Ajustez la taille si vous utilisez un caractère de loupe */
  z-index: 10; /* Assurez-vous que ce z-index est supérieur à celui des autres éléments */
  /* Ajoutez d'autres styles pour votre loupe ici */
}
