.welcome {
  text-align: center;
  font-family: "Alegreya Sans";
  color: #013E64;
  margin-left: 17%;
  margin-right: 2%;
}
  
  .dashboard-header {
    text-align: center;
    margin-bottom: 20px;
  }
  
  .dashboard-stats, .dashboard-charts {
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
  }
  
  .stats-card {
    background-color: #ffffff;
    padding: 20px;
    margin: 10px;
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(0,0,0,0.1); /* Ombre portée pour les cartes */
  }
  
  /* Adapter à votre balisage HTML et ajouter des classes spécifiques pour le reste du style */
  