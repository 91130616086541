.sidebar {
    width: 15%;
    padding-top: 1%;
    position: fixed;
    left: 10;
    right : 10;
    top: 0;
    bottom: 0;
    background-color: #145C8B;
    color: #ffffff;
    overflow-y: auto; /* Ajoute un défilement si le contenu est plus long que la fenêtre */
  }
  
  .sidebar-logo {
    text-align: center;
    margin-top: -150px;
    margin-bottom: -150px;
    
  }
  
  .sidebar-logo img {
    width: 100%; /* ou une largeur fixe en px */
    max-width: 200px; /* Assurez-vous que le logo ne soit pas trop grand */
  }
  
  .sidebar-menu {
    display: flex;
    flex-direction: column;
  }
  
  .sidebar a {
    text-decoration: none;
    color: #ffffff;
    display: flex;
    align-items: center;
    padding: 10px;
    margin: 5px 0;
    transition: all 0.3s;
  }
  
  .sidebar-icon {
    margin-right: 10px;
    /* Vous pouvez également définir une taille pour les icônes si nécessaire */
  }
  
  .sidebar a:hover {
    background-color: #C5C3D4;
  }
  
  .sidebar-footer{
    position: absolute;
    bottom: 0;
    width: 100%;
    margin-bottom: 20px;
  }
  
  div[disabled]
  {
      pointer-events: none;
      opacity: 0.7; 
  }