.dashboard {
    text-align: center;
    font-family: "Alegreya Sans";
    color: #013E64;
    margin-left: 15%;
    margin-right: 2%;
  }
  .carbonImpact{
    color: #0a5887;
    text-align: center;
  }

  .carbonGoal{
    color: #BF9E27;
    text-align: center;
    margin-top: 10%;
  }
  .dashboard-header {
    text-align: center;
  }
  
  .dashboard-stats, .dashboard-charts {
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
  }
  
  .stats-card {
    background-color: #ffffff;
    border-radius: 6px;
    box-shadow: 0 0 1px rgba(0,0,0,0.1); /* Ombre portée pour les cartes */
  }
  
  