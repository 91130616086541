.help {
  font-family: "Alegreya Sans";
  color: #013E64;
  margin-left: 15%;
  margin-right: 1%;
  width: '100%'
  }
  
  .help-header {
    text-align: center;
    margin-bottom: 20px;
  }
  
  .accordion-details {
    text-align: left;
    font-size: 1.2em;
  }  